<template>
  <v-container fluid class="pa-0">
    <v-row
      justify="center"
      class="pt-15 mt-4 mx-0"
      style="z-index: 2; position: relative"
    >
      <v-col cols="11" md="10" lg="9">
        <p class="titleI_beneficiile fontR mb-0">Beneficiile</p>
        <p class="subtitle_beneficiile fontL font_wrap pl-sm-3 ml-sm-15 mb-0">
          lucrului cu noi
        </p>
        <p class="textI_beneficiile fontR font_wrap pl-sm-6 ml-sm-15">
          în ședințe private ne cunoaștem
        </p>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="pt-12 mt-4 mx-0"
      style="z-index: 2; position: relative"
    >
      <v-col cols="11" md="10" lg="9">
        <v-row justify="center">
          <v-col cols="6" md="3">
            <v-card class="cards pa-4" height="100%">
              <p class="pt-15 mt-15 cards_title mb-0 fontR font_wrap">
                Mod confidențial
              </p>
              <p class="cards_subtitle fontR mb-0 font_wrap">
                toate sesiunile au loc într-un studio privat gest.md fără
                persoane neautorizate
              </p>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card class="cards pa-4" height="100%">
              <p class="pt-15 mt-15 cards_title mb-0 fontR font_wrap">
                Față în față
              </p>
              <p class="cards_subtitle fontR mb-0 font_wrap">
                Sesiunile sunt susținute în fața cu specialistul în cabinetul
                confortabil
              </p>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card class="cards pa-4" height="100%">
              <p class="pt-15 mt-15 cards_title mb-0 fontR font_wrap">Online</p>
              <p class="cards_subtitle fontR mb-0 font_wrap">
                Pentru clienții din alte țări
              </p>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card class="cards pa-4" height="100%">
              <p class="pt-15 mt-15 cards_title mb-0 fontR font_wrap">
                Diversitate
              </p>
              <p class="cards_subtitle fontR mb-0 font_wrap">
                Rezolvarea oricăror aspecte ale inteligenței cinetice și
                profilării
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-15 mx-0"
      style="z-index: 2; position: relative; margin-bottom: -50px"
    >
      <v-card
        href="#"
        link
        class="card_link pa-9 rounded-pill text-center fontL"
      >
        <p class="my-15">
          ÎNSCRIEȚI-VĂ ACUM<br />
          PENTRU O CONSULTARE
        </p>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BeneficiilePage",

  data: () => ({}),
  mounted() {
    let beneficiile = document.getElementById("beneficiile");
    window.addEventListener("scroll", function () {
      Array.from(this.document.getElementsByClassName("titleI_beneficiile")).forEach(
        function (title) {
          const bottomOfObject =
            (beneficiile.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("titleI_beneficiile_anim");
          }
        }
      );

      Array.from(this.document.getElementsByClassName("subtitle_beneficiile")).forEach(
        function (title) {
          const bottomOfObject =
            (beneficiile.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("subtitle_beneficiile_anim");
          }
        }
      );

      Array.from(this.document.getElementsByClassName("textI_beneficiile")).forEach(
        function (title) {
          const bottomOfObject =
            (beneficiile.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("textI_beneficiile_anim");
          }
        }
      );
    });
  },
};
</script>
<style scoped>
.card_link:hover {
  border: 2px solid #ae9183;
  background: #ae9183;
}
.card_link {
  transition: 0.2s;
  border: 2px solid #ae9183;
  background: transparent;
  color: #16162e;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 187%;
  letter-spacing: -0.8px;
}
.cards_subtitle {
  color: #16162e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 187%;
  letter-spacing: -0.6px;
}
.cards_title {
  color: #16162e;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 187%;
  letter-spacing: -0.8px;
}
.cards:hover {
  border: 1.5px solid #16162e;
  background: #f0e7e0;
}
.cards {
  border: 1.5px solid #ae9183;
  transition: 0.2s;
  border-radius: 14px;
  background: #ae9183;
  box-shadow: 10px 20px 33px 0px rgba(22, 22, 46, 0.32) !important;
}
.textI_beneficiile {
  color: #16162e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.765px;
  transform: translate(0px, 50%);
  opacity: 0;
}
.subtitle_beneficiile {
  color: #16162e;
  font-size: 34px;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
  letter-spacing: -0.6px;
  transform: translate(50%, 0px);
  opacity: 0;
}
.titleI_beneficiile {
  color: #16162e;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: -1.6px;
  transform: translate(-50%, 0px);
  opacity: 0;
}
.titleI_beneficiile_anim {
  transition: 0.7s;
  transform: translate(0%, 0px);
  opacity: 1;
}

.subtitle_beneficiile_anim {
  transition: 0.7s;
  transition-delay: 0.5s;
  transform: translate(0%, 0px);
  opacity: 1;
}
.textI_beneficiile_anim {
  transition: 0.7s;
  transition-delay: 1s;
  transform: translate(0%, 0px);
  opacity: 1;
}
</style>
