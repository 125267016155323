<template>
  <v-container fluid class="pa-0 container_blue">
    <v-row justify="center" class="ma-0">
      <v-col cols="12" align-self="center">
        <v-card-text class="py-2 white--text text-center d-flex justify-center">
          <svg
            class="ml-sm-15"
            width="26"
            viewBox="0 0 63 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.4253 36.9315C38.8732 33.3828 23.5629 22.1518 21.0193 19.4407C16.677 15.805 17.4126 9.65838 22.3182 7.18122C22.5954 7.04174 22.8797 6.91679 23.1699 6.80688C21.5714 7.2121 19.9674 7.61451 18.369 8.02347C16.1735 8.65142 15.0824 9.75571 14.6257 10.3107C11.7789 13.7199 14.1344 18.6864 14.4001 19.2451C15.0903 20.688 16.0875 21.9627 17.3218 22.98L32.3186 35.9413C33.3349 36.8772 35.0325 38.7713 35.201 41.3383C35.4911 45.8425 30.8905 49.5737 26.6671 50.5254C25.5499 50.7771 24.4009 50.8566 23.2597 50.7613C24.0025 51.158 24.7843 51.477 25.5927 51.713C27.2607 52.1917 29.0076 52.3313 30.7305 52.1238C37.4919 50.1726 41.6377 43.6564 39.4253 36.9315Z"
              fill="#fff"
            />
            <path
              d="M63 35.5894L62.5068 35.7101C62.3438 34.9036 62.1313 34.1079 61.8704 33.3275C61.3024 31.7122 60.8045 31.0665 58.8084 31.0665H58.0092C57.8903 31.1254 57.775 31.1914 57.6639 31.264C57.2772 31.5082 56.9439 31.8282 56.684 32.2045C56.3188 32.7446 56.1135 33.3768 56.0917 34.0284C56.1073 38.309 56.1228 42.591 56.1384 46.8747C55.7725 47.1685 55.4057 47.4568 55.0332 47.7347C53.9963 48.5252 52.9432 49.2551 51.8738 49.9245C33.683 61.3801 12.024 56.1198 4.46903 43.3625C-2.37848 31.8039 0.725686 11.3138 15.3509 3.92631C26.1177 -1.51745 41.9923 -0.579738 52.3249 5.67071L54.0431 16.5947H53.2673C48.2063 -1.86838 17.6147 -1.49686 10.1 15.0441C4.16395 26.4659 6.44365 44.3264 18.7031 51.1898C28.2234 56.8329 42.5108 54.9098 50.778 47.7422L50.8622 34.0256C50.8787 33.4004 50.7439 32.7804 50.4691 32.2185C50.2996 31.8725 50.0812 31.5526 49.8206 31.2687C49.756 31.1975 49.6915 31.132 49.6278 31.0712H48.0659C46.2036 31.0712 45.906 31.7993 45.439 32.8493C45.0085 33.8955 44.8392 34.6667 44.5734 35.7129H44.0437C44.2748 33.5334 44.4396 31.3548 44.5416 29.3343H44.8747C45.2743 29.9416 45.4708 30.024 46.6706 30.024H60.7381C61.702 30.024 62.0005 29.9033 62.3019 29.3343H62.7024C62.7024 30.9879 62.8353 33.3715 63 35.5894Z"
              fill="#fff"
            />
          </svg>
          <span class="fontR ml-6 mr-8 text_logo">gest.md</span>
          <span class="border_right"></span>
          <v-btn href="#" class="ml-8 mr-2 white--text" icon>
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn href="#" class="mx-2 white--text" icon>
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          <v-btn href="#" class="mx-2 white--text" icon>
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="ma-0 white--text"
      style="align-items: center"
    >
      <v-btn href="#" text class="footer_buttons fontR px-sm-8">Home</v-btn>|
      <v-btn href="#" text class="footer_buttons fontR px-sm-8">Service</v-btn>|
      <v-btn href="#" text class="footer_buttons fontR px-sm-8">Resource</v-btn
      >|
      <v-btn href="#" text class="footer_buttons fontR px-sm-8">Contact</v-btn>|
      <v-btn href="#" text class="footer_buttons fontR px-sm-8">About</v-btn>
    </v-row>
    <v-row justify="center" class="ma-0">
      <v-col cols="12" sm="10" md="8">
        <v-card-text class="text-center fontR text_footer">
          Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom diska.
          Jinesade bel när feras redorade i belogi. FAR paratyp i muvåning, och
          pesask vyfisat. Viktiga poddradio har un mad och inde.
        </v-card-text>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0 white--text">
      <v-card-text class="text-center fontR text_footer_bottom py-2">
        All rights reserved © | 2023 gest.md | Powered by amark.pro
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterPage",

  data: () => ({}),
};
</script>
<style scoped>
.text_footer_bottom {
  color: rgba(240, 231, 224, 0.17);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.17px;
}
.text_footer {
  color: #f0e7e0 !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.footer_buttons {
  color: #f0e7e0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-transform: inherit;
  line-height: normal;
}
.border_right {
  border-right: 1px solid #f0e7e0;
}
.text_logo {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.765px;
  line-height: 187%;
}
.container_blue {
  background-color: #16162e;
}
</style>
