<template>
  <v-main>
    <first-page />
    <second-page  />
    <istoria-page id="istoria" />
    <beneficiile-page id="beneficiile" />
    <scopul-page id="scopul" />
    <!-- <tarife-page id="tarife" />
    <răspunsuri-page /> -->
    <contacts-page id="contacts" />
    <footer-page />
  </v-main>
</template>

<script>
import FirstPage from "../components/FirstPage";
import SecondPage from "../components/SecondPage";
import IstoriaPage from "../components/Istoria";
import BeneficiilePage from "../components/Beneficiile";
import ScopulPage from "../components/Scopul";
/* import TarifePage from "../components/Tarife";
import RăspunsuriPage from "../components/Răspunsuri"; */
import ContactsPage from "../components/Contacts";
import FooterPage from "../components/FooterPage";

export default {
  name: "HomePage",
  components: {
    FirstPage,
    SecondPage,
    IstoriaPage,
    BeneficiilePage,
    ScopulPage,
   /*  TarifePage,
    RăspunsuriPage, */
    ContactsPage,
    FooterPage
  },
};
</script>
