<template>
  <v-container fluid class="pa-0 container_blue">
    <v-row justify="center" class="py-15 mx-0" style="position: relative">
      <v-col cols="11" md="10" lg="9" style="z-index: 1; position: relative">
        <v-row justify="center" class="">
          <v-col cols="10" sm="6" align-self="center">
            <v-img width="100%" src="../assets/contacts.png"></v-img>
          </v-col>
          <v-col cols="10" sm="6" align-self="center" class="pl-sm-12 pr-sm-8">
            <v-row class="ma-0">
              <span class="btns_contacts fontR px-0" color="#AE9183">
                înscrieți la consultare
              </span>
              <v-btn href="#" class="ml-8 mr-2 white--text" icon>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn href="#" class="mx-2 white--text" icon>
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn href="#" class="mx-2 white--text" icon>
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </v-row>
            <!-- <v-btn
              class="btns_contacts fontR px-0"
              color="#AE9183"
              text
              @click="tab = 0"
            >
              înscrieți la consultare
            </v-btn> -->
            <!-- <v-btn
              class="btns_contacts fontR ml-6 px-0"
              color="#AE9183"
              text
              @click="tab = 1"
            >
              contactați
            </v-btn> -->

            <v-tabs-items v-model="tab" class="">
              <v-tab-item>
                <v-card flat color="transparent" dark>
                  <v-card-text class="fontR white--text px-0 py-10">
                    Aveți întrebări? Doriți să încercați? Nu ezita sa intrebi
                    orice!
                  </v-card-text>
                  <v-text-field
                    label="Nume & Prenume"
                    outlined
                    class="fontR text_fields"
                    dense
                  ></v-text-field>
                  <v-text-field
                    label="Email"
                    outlined
                    class="fontR text_fields"
                    dense
                  ></v-text-field>
                  <v-text-field
                    label="Subiect"
                    outlined
                    class="fontR text_fields"
                    dense
                  ></v-text-field>
                  <v-textarea
                    label="Mesaj"
                    outlined
                    class="fontR text_fields"
                    dense
                  ></v-textarea>
                  <v-checkbox
                    class="fontR mt-0"
                    v-model="checkbox"
                    label="Sunt de acord cu Termenii și Condițiile site-ului și prelucrarea datele mele"
                  ></v-checkbox>
                  <v-btn
                    block
                    class="fontR btn_trimite"
                    color="#AE9183"
                    @click="SendMessage()"
                  >
                    Trimite
                  </v-btn>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat color="transparent" dark> </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactsPage",
  data: () => ({
    tab: 0,
    checkbox: false,
  }),
  methods: {
    SendMessage() {},
  },
};
</script>
<style>
.text_fields .v-input__slot fieldset {
  border-radius: 10px !important;
  border: 1px solid #f0e7e0 !important;
}
</style>
<style scoped>
.btn_trimite {
  text-transform: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.17px;
  border-radius: 10px;
}
.text_fields {
  color: #9e9e9e;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.17px;
}
.btns_contacts {
  color: #ae9183;
  text-transform: inherit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.765px;
  align-items: center;
  display: flex;
}
.container_blue {
  background-color: #16162e;
}
</style>
