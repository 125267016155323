<template>
  <v-container fluid class="container_blue py-14">
    <v-row justify="center" class="ma-0">
      <svg
        width="44"
        height="33"
        viewBox="0 0 44 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.3857 31.9191L34.4003 31.92H34.415C36.69 31.92 38.6315 31.234 40.2058 29.8449C41.8114 28.4283 42.585 26.4136 42.585 23.88C42.585 21.5964 41.849 19.7234 40.347 18.3152C39.4146 17.4411 38.3015 16.809 37.0164 16.4162C36.529 16.2672 36.1173 15.9551 35.8802 15.5837C35.6486 15.221 35.5877 14.8155 35.7533 14.4165C36.289 13.1252 37.0165 11.8279 37.9391 10.5246C38.994 9.11876 40.0844 7.92058 41.2092 6.92675C42.2147 6.03836 42.5108 4.48917 41.6816 3.34895L40.634 1.90853C39.9928 1.02689 38.8094 0.618808 37.7662 1.11539C36.4675 1.73361 35.1999 2.56589 33.963 3.60754L33.9628 3.60741L33.9528 3.6163C32.3594 5.0327 30.9449 6.66879 29.709 8.52265C28.4741 10.375 27.4601 12.3592 26.6669 14.4744L26.6636 14.4831L26.6607 14.4919C25.9486 16.628 25.595 18.9353 25.595 21.41C25.595 24.0146 26.2702 26.3639 27.6262 28.4431C29.0337 30.6013 31.3231 31.739 34.3857 31.9191ZM9.96074 31.9198L9.96788 31.92H9.97502C12.0859 31.92 13.9355 31.2284 15.5006 29.8496C17.1933 28.4361 18.015 26.4204 18.015 23.88C18.015 21.5964 17.279 19.7234 15.777 18.3152C14.8446 17.4411 13.7315 16.809 12.4464 16.4162C11.959 16.2672 11.5473 15.9551 11.3102 15.5837C11.0786 15.221 11.0177 14.8155 11.1833 14.4165C11.719 13.1252 12.4465 11.8279 13.3691 10.5246C14.424 9.11875 15.5144 7.92058 16.6392 6.92675C17.6447 6.03836 17.9408 4.48917 17.1116 3.34895L16.064 1.90853C15.4228 1.02689 14.2394 0.618808 13.1962 1.11539C11.8975 1.73361 10.6299 2.56589 9.39296 3.60754L9.39284 3.60741L9.38284 3.6163C7.78939 5.0327 6.37491 6.66879 5.139 8.52265C3.90412 10.375 2.89008 12.3592 2.09686 14.4744L2.09361 14.4831L2.09068 14.4919C1.37864 16.628 1.02502 18.9353 1.02502 21.41C1.02502 24.0146 1.70008 26.4035 3.05103 28.565L3.05095 28.5651L3.05622 28.5731C4.47015 30.7412 6.81395 31.8299 9.96074 31.9198Z"
          stroke="#F0E7E0"
        />
      </svg>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="7" lg="6">
        <p class="text-center subtext fontR">
          „Dezvoltarea Inteligenței Kinetice nu înseamnă doar să te miști cu
          încredere și să negociezi cu succes. Este o oportunitate de a-ți
          debloca potențialul, de a depăși granițele îndoielii și de a realiza
          ceva grozav. Crede-mă ca Specialist în Inteligență Kinetică și
          împreună îți vom transforma viața. Nu aștept momentul perfect,
          creează-l acum.”
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SecondPage",

  data: () => ({}),
};
</script>
<style scoped>
.container_blue {
  background: #16162e;
}
.subtext {
  color: #ae9183;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 157%;
  letter-spacing: -0.765px;
}
</style>
