<template>
  <v-container fluid class="pa-0" style="position: relative">
    <v-img src="../assets/Vector_blue.png" class="img_blue">
      <v-row
        justify="center"
        class="mt-4 mx-0"
        style="z-index: 2; position: relative"
      >
        <v-col cols="11" offset-md="1" md="9" lg="9">
          <p class="titleI_scopul fontR mb-0">Scopul nostru</p>
          <p class="subtitle_scopul fontL font_wrap pl-sm-12 ml-sm-15 mb-0">
            este să schimbăm ceea ce este ascuns
          </p>
          <p class="textI_scopul fontR font_wrap pl-sm-7 ml-sm-15">
            și se transformă într-o parte puternică
          </p>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="pt-12 mt-4 mb-12 mx-0"
        style="position: relative"
      >
        <v-col cols="11" md="10" lg="9" style="z-index: 1; position: relative">
          <v-row justify="center" class="mb-15 pb-15">
            <v-col cols="10" sm="5">
              <v-img
                width="100%"
                lazy-src="../assets/scopulimgSmall.png"
                src="../assets/scopulimg.png"
              ></v-img>
            </v-col>
            <v-col
              cols="10"
              sm="7"
              align-self="center"
              class="pl-sm-12 pr-sm-8"
            >
              <ul class="scopul_list fontR">
                <li class="my-10">deplină confidențialitate și anonimat</li>
                <li class="my-10">
                  spațiu fără valoare. Puteți fi în orice stare emoțională, vă
                  puteți întâlni sentimentele.
                </li>
                <li class="my-10">
                  este important să stabilim o conexiune și încredere pentru
                  a-ți atinge obiectivele.
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>

        <svg
          class="svg_linia"
          width="100%"
          viewBox="0 0 1512 450"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-11 291.765C123.498 155.468 198.905 181.049 333.404 291.765C442.849 392.284 505.041 407.908 618.905 291.765C673.979 244.274 670.456 278.056 618.905 417.82C584.806 540.49 1105.39 250.015 862.149 417.82C618.905 585.624 658.82 -20.5209 862.149 59.327C1065.48 139.175 1035.95 -105.266 1523 59.327"
            stroke="#F0E7E0"
          />
        </svg>
      </v-row>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: "ScopulPage",

  data: () => ({}),
  mounted() {
    let scopul = document.getElementById("scopul");
    window.addEventListener("scroll", function () {
      Array.from(this.document.getElementsByClassName("titleI_scopul")).forEach(
        function (title) {
          const bottomOfObject =
            (scopul.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("titleI_scopul_anim");
          }
        }
      );

      Array.from(
        this.document.getElementsByClassName("subtitle_scopul")
      ).forEach(function (title) {
        const bottomOfObject =
          (scopul.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
        const bottomOfWindow = window.pageYOffset + window.innerHeight;

        if (bottomOfWindow > bottomOfObject) {
          title.classList.add("subtitle_scopul_anim");
        }
      });

      Array.from(this.document.getElementsByClassName("textI_scopul")).forEach(
        function (title) {
          const bottomOfObject =
            (scopul.offsetTop + title.offsetTop + title.offsetHeight) * 1.1;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("textI_scopul_anim");
          }
        }
      );
    });
  },
};
</script>
<style scoped>
.svg_linia {
  position: absolute;
  bottom: 0px;
  z-index: 0;
  left: 0px;
}
.scopul_list {
  color: #ae9183;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 187%;
  letter-spacing: -0.765px;
}
.textI_scopul {
  color: #f0e7e0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.765px;
  transform: translate(0px, 50%);
  opacity: 0;
}
.subtitle_scopul {
  color: #f0e7e0;
  font-size: 34px;
  font-style: normal;
  font-weight: 200;
  line-height: 120%;
  letter-spacing: -0.6px;
  transform: translate(50%, 0px);
  opacity: 0;
}
.titleI_scopul {
  color: #ae9183;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: -1.6px;
  transform: translate(-50%, 0px);
  opacity: 0;
}
.img_blue {
  padding-top: 200px;
}
.titleI_scopul_anim {
  transition: 0.7s;
  transform: translate(0%, 0px);
  opacity: 1;
}

.subtitle_scopul_anim {
  transition: 0.7s;
  transition-delay: 0.5s;
  transform: translate(0%, 0px);
  opacity: 1;
}
.textI_scopul_anim {
  transition: 0.7s;
  transition-delay: 1s;
  transform: translate(0%, 0px);
  opacity: 1;
}
</style>
