<template>
  <v-container
    fluid
    class="pa-0 scroll-container scroll-sequence__container apple-container"
  >
    <div class="scroll-sequence apple-sequence"></div>
    <div class="scroll-sequence__content">
      <div class="speak">
        <v-row class="my-8 mx-0">
          <v-col cols="10" md="6" lg="5" offset="1" style="z-index: 2">
            <v-card flat color="transparent">
              <v-card-title class="cards_title fontR font_wrap">
                Ce este mișcare intiligentă?
              </v-card-title>
              <hr class="mx-4 cards_line" />
              <v-card-text class="cards_text fontR text-justify font_wrap">
                O inteligență cinetică bine dezvoltată poate fi utilă pentru
                negocieri de succes, deoarece include elemente de abilități de
                comunicare, gesturi, expresii faciale, postură, mișcări ale
                corpului și alte aspecte fizice care pot influența percepția și
                interacțiunea cu alte persoane.
              </v-card-text>
            </v-card>
          </v-col>
          <svg
            class="svg_1"
            width="400"
            viewBox="0 0 528 563"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M526.5 281.5C526.5 436.142 401.361 561.5 247 561.5C92.6389 561.5 -32.5 436.142 -32.5 281.5C-32.5 126.858 92.6389 1.5 247 1.5C401.361 1.5 526.5 126.858 526.5 281.5Z"
              stroke="#F0E7E0"
              stroke-width="3"
            />
          </svg>
        </v-row>
      </div>
      <div class="speakr">
        <v-row class="my-8" justify="end">
          <v-col cols="10" md="6" lg="5" style="z-index: 2">
            <v-card flat color="transparent">
              <v-card-title class="cards_title fontR font_wrap">
                Obține succesE în afaceri și viața personală.
              </v-card-title>
              <hr class="mx-4 cards_line" />
              <v-card-text class="cards_text fontR text-justify font_wrap">
                Învață și stăpânește abilitățile de negociere de succes,
                comunicare încrezătoare și influențare pe ceilalți pentru a-ți
                atinge obiectivele și a-ți depăși așteptările.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="1"></v-col>
          <svg
            class="svg_2"
            width="100%"
            viewBox="0 0 1512 340"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="zipper"
              opacity="0.8"
              d="M400 141C400 201.751 350.751 251 290 251C229.249 251 180 201.751 180 141C180 80.2487 229.249 31 290 31C350.751 31 400 80.2487 400 141Z"
              stroke="#F0E7E0"
              stroke-width="3"
            />
          </svg>
        </v-row>
      </div>
      <div class="speak">
        <v-row class="my-8 mx-0">
          <v-col cols="10" md="6" lg="5" offset="1" style="z-index: 2">
            <v-card flat color="transparent">
              <v-card-title class="cards_title fontR font_wrap">
                Transformă-ți corpul pentru rezultate impresionante.
              </v-card-title>
              <hr class="mx-4 cards_line" />
              <v-card-text class="cards_text fontR text-justify font_wrap">
                Cursuri de inteligență cinetică: obțineți strategii eficiente de
                negociere, curaj și încredere pentru a vă realiza ambițiile.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import anime from "animejs";
export default {
  name: "FirstPage",

  data: () => ({}),
  mounted() {
    let anim = false;
    const appleSequenceImages = [];
    /* for (let i = 46; i <= 128; i++) {
      appleSequenceImages.push(
        `gif_imgs%2Fezgif-frame-${`${
          i < 100 ? "0" + i : i
        }`}.jpg?alt=media&token=4d8c3f8d-b7e2-4cd9-8f50-d1ba91e85cb7`
      );
    } */
    for (let i = 10; i <= 131; i++) {
      appleSequenceImages.push(`${`000${i}`.slice(-4)}.jpg`);
    }
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    class EventEmitter {
      listeners = {};
      addListener(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        this.listeners[eventName].push(fn);
        return this;
      }
      on(eventName, fn) {
        return this.addListener(eventName, fn);
      }
      once(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        const onceWrapper = () => {
          fn();
          this.off(eventName, onceWrapper);
        };
        this.listeners[eventName].push(onceWrapper);
        return this;
      }
      off(eventName, fn) {
        return this.removeListener(eventName, fn);
      }
      removeListener(eventName, fn) {
        let lis = this.listeners[eventName];
        if (!lis) return this;
        for (let i = lis.length; i > 0; i--) {
          if (lis[i] === fn) {
            lis.splice(i, 1);
            break;
          }
        }
        return this;
      }
      emit(eventName, ...args) {
        let fns = this.listeners[eventName];
        if (!fns) return false;
        fns.forEach((f) => {
          f(...args);
        });
        return true;
      }
      listenerCount(eventName) {
        let fns = this.listeners[eventName] || [];
        return fns.length;
      }
      rawListeners(eventName) {
        return this.listeners[eventName];
      }
    }
    class Canvas {
      constructor(e) {
        this.images = e.images;
        this.container = e.container;
        this.cover = e.cover;
        this.displayIndex = 0;
        this.container_images = e.container.children[0];
      }

      setup() {
        this.canvas = document.createElement("canvas");

        if (!this.container) {
          return;
        }
        this.container_images.appendChild(this.canvas);
        this.ctx = this.canvas.getContext("2d");
        // Create gradient
        /* const grd = this.ctx.createLinearGradient(0, 0, 200, 0);
        this.ctx.addColorStop(0, "red");
        this.ctx.addColorStop(1, "white");

        // Fill with gradient
        this.ctx.fillStyle = grd;
        this.ctx.fillRect(10, 10, 150, 80); */

        window.addEventListener("resize", () => this.resize());
        this.resize();
      }

      renderIndex(e) {
        if (this.images[e]) {
          return this.drawImage(e);
        }
        // Find closest loaded image
        for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
          if (this.images[r]) {
            t = r;
            break;
          }
        for (
          var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length;
          i < o;
          i++
        )
          if (this.images[i]) {
            n = i;
            break;
          }
        this.images[t]
          ? this.drawImage(t)
          : this.images[n] && this.drawImage(n);
      }

      drawImage(e) {
        this.displayIndex = e;
        if (!this.ctx) {
          return;
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        const x = Math.floor(
          (this.canvas.width - this.images[this.displayIndex].naturalWidth) / 2
        );
        const y = Math.floor(
          (this.canvas.height - this.images[this.displayIndex].naturalHeight) /
            2
        );
        if (this.cover) {
          this.drawImageCover(this.ctx, this.images[this.displayIndex]);
        } else {
          this.ctx.drawImage(this.images[this.displayIndex], x, y);
        }
      }

      resize() {
        const w = this.container.clientWidth;
        const h = this.container.clientHeight;
        this.canvas.style.height = `${h}px`;
        this.canvas.style.width = `${w}px`;
        this.canvas.height = h;
        this.canvas.width = w;

        this.renderIndex(this.displayIndex);
      }

      drawImageCover(ctx, img, x, y, w, h, offsetX, offsetY) {
        if (arguments.length === 2) {
          x = y = 0;
          w = ctx.canvas.width;
          h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;
        offsetY = typeof offsetY === "number" ? offsetY : 0.5;

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;
        if (offsetX > 1) offsetX = 1;
        if (offsetY > 1) offsetY = 1;

        var iw = img.width,
          ih = img.height,
          r = Math.min(w / iw, h / ih),
          nw = iw * r, // new prop. width
          nh = ih * r, // new prop. height
          cx,
          cy,
          cw,
          ch,
          ar = 1;

        // decide which gap to fill
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        // fill image in dest. rectangle
        console.log(ctx);
        ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
      }
    }
    class ImgLoader extends EventEmitter {
      constructor(opts) {
        super();
        this.images = opts.imgsRef;
        this.imageNames = opts.images;
        this.imagesRoot = opts.imagesRoot;
        this.sequenceLength = opts.images.length;
        this.priorityFranes = opts.priorityFrames;
        this.complete = false;
        this.loadIndex = 0;

        this.priorityQueue = this.createPriorityQueue();
        this.loadingQueue = this.createLoadingQueue();

        this.loadNextImage();
      }

      loadImage(e) {
        if (this.images[e]) {
          return this.loadNextImage();
        }
        const onLoad = () => {
          img.removeEventListener("load", onLoad);
          this.images[e] = img;

          if (e === 0) {
            this.emit("FIRST_IMAGE_LOADED");
          }
          this.loadNextImage();
        };
        const img = new Image();
        img.addEventListener("load", onLoad);
        img.src = (this.imagesRoot ? this.imagesRoot : "") + this.imageNames[e];
      }

      loadNextImage() {
        if (this.priorityQueue.length) {
          this.loadImage(this.priorityQueue.shift());
          if (!this.priorityQueue.length) {
            this.emit("PRIORITY_IMAGES_LOADED");
          }
        } else if (this.loadingQueue.length) {
          this.loadImage(this.loadingQueue.shift());
        } else {
          this.complete = true;
          this.emit("IMAGES_LOADED");
        }
      }

      createPriorityQueue() {
        const p = this.priorityFrames || [];
        if (!p.length) {
          p.push(0);
          p.push(Math.round(this.sequenceLength / 2));
          p.push(this.sequenceLength - 1);
        }
        return p;
      }

      createLoadingQueue() {
        return this.imageNames
          .map((s, i) => i)
          .sort((e, n) => {
            return (
              Math.abs(e - this.sequenceLength / 2) -
              Math.abs(n - this.sequenceLength / 2)
            );
          });
      }
    }
    class ScrollSequence {
      constructor(opts) {
        this.opts = {
          container: "body",
          starts: "out",
          ends: "out",
          imagesRoot: "",
          cover: false,
          ...opts,
        };
        this.container =
          typeof opts.container === "object"
            ? opts.container
            : document.querySelector(opts.container);

        this.scrollWith = !opts.scrollWith
          ? this.container
          : typeof opts.scrollWith === "object"
          ? opts.scrollWith
          : document.querySelector(opts.scrollWith);

        this.images = Array(opts.images.length);
        this.imagesToLoad = opts.images;
        this.priorityFrames = opts.priorityFrames;

        this.loader = new ImgLoader({
          imgsRef: this.images,
          images: this.imagesToLoad,
          imagesRoot: this.opts.imagesRoot,
          priorityFrames: this.priorityFrames,
        });

        this.canvas = new Canvas({
          container: this.container,
          images: this.images,
          cover: this.opts.cover,
        });

        this.init();
      }

      init() {
        this.canvas.setup();
        this.loader.once("FIRST_IMAGE_LOADED", () => {
          this.canvas.renderIndex(0);
        });
        this.loader.once("PRIORITY_IMAGES_LOADED", () => {
          window.addEventListener("scroll", () => this.changeOnWindowScroll());
        });
        this.loader.once("IMAGES_LOADED", () => {
          //  console.log("Sequence Loaded");
        });
      }

      changeOnWindowScroll() {
        const step = 100 / (this.images.length - 1);
        const mapToIndex = Math.floor(this.percentScrolled / step);
        requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
      }
      get percentScrolled() {
        const { starts, ends } = this.opts;
        const el = this.scrollWith;
        const doc = document.documentElement;
        const clientOffsety = doc.scrollTop || window.pageYOffset;
        if (!el) {
          return 0;
        }
        const elementHeight = el.clientHeight || el.offsetHeight;
        const clientHeight = doc.clientHeight;
        let target = el;
        let offsetY = 0;
        do {
          offsetY += target.offsetTop;
          target = target.offsetParent;
        } while (target && target !== window);

        let u = clientOffsety - offsetY;
        let d = elementHeight + clientHeight;

        if (starts === "out") u += clientHeight;
        if (ends === "in") d -= clientHeight;
        if (starts == "in") d -= clientHeight;

        const value = (u / d) * 80;
        return value > 100 ? 100 : value < 0 ? 0 : value;
      }
    }

    new ScrollSequence({
      container: ".apple-container",
      scrollWith: ".scroll-container",
      images: appleSequenceImages,
      imagesRoot:
        "https://www.apple.com/105/media/us/airpods-pro/2019/1299e2f5_9206_4470_b28e_08307a42f19b/anim/sequence/large/02-head-bob-turn/",
      priorityFrames: [],
      /* imagesRoot:
        "https://firebasestorage.googleapis.com/v0/b/gest-f73a4.appspot.com/o/",
      priorityFrames: [], */
      cover: true,
      playUntil: "scroll-out",
      starts: "in",
    });
    let self = this;

    window.addEventListener("scroll", function () {
      Array.from(this.document.getElementsByClassName("speak")).forEach(
        function (title) {
          const bottomOfObject = title.offsetTop + title.offsetHeight * 0.7;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject) {
            title.classList.add("speak_anim");
          }
        }
      );
      Array.from(this.document.getElementsByClassName("speakr")).forEach(
        function (title) {
          const bottomOfObject = title.offsetTop + title.offsetHeight * 0.7;
          const bottomOfWindow = window.pageYOffset + window.innerHeight;

          if (bottomOfWindow > bottomOfObject && anim == false) {
            self.animateBox();
            anim = true;
            title.classList.add("speakr_anim");
          }
        }
      );
    });
  },
  methods: {
    animateBox() {
      anime({
        targets: "#zipper",
        d: [
          {
            value:
              "M400 141C400 201.751 350.751 251 290 251C229.249 251 180 201.751 180 141C180 80.2487 229.249 31 290 31C350.751 31 400 80.2487 400 141Z",
          },
          {
            value:
              "M1 326.954C191.947 402.25 772.217 59.7021 470.844 285.817C341.148 368.519 643.69 265.069 1205.11 71.85C1304.91 32.6909 1210.4 117.678 1001.07 298.241C880.094 419.065 1151.04 236.812 1512 2",
          },
        ],
        duration: 3000,
        delay: 0,
        easing: "easeInOutExpo",
      });
    },
  },
};
</script>
<style lang="scss">
.scroll-container {
  height: 190vh;
  background: black;
}

.speak {
  transform: translate(-50%, 50px);
  opacity: 0;
}
.speak_anim {
  transition: 1s ease-out;
  transform: translate(0%, 0px);
  opacity: 1;
}
.speakr {
  transform: translate(50%, 0px);
  opacity: 0;
}
.speakr_anim {
  transition: 1s ease-in;

  transform: translate(0%, 0px);
  opacity: 1;
}

.scroll-sequence__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.scroll-sequence {
  height: 190vh;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(5, 10, 55, 0.02) 7.81%,
    #16162e 99.27%
  );

  /* position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -5;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
}
</style>
<style>
.background_first .v-image__image {
  background-blend-mode: luminosity;
  filter: grayscale(0.8);
}
.background_first .v-responsive__content {
  display: flex;
  flex-direction: column;
}
</style>
<style scoped>
.svg_2 {
  position: absolute;
  left: 0px;
}
.svg_1 {
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 0;
}
.cards_text {
  color: #f0e7e0 !important;
  text-shadow: 0px 4px 15px rgba(48, 48, 48, 0.87);
  font-style: normal;
  font-weight: 400;
  line-height: 186.5%;
  letter-spacing: -0.1px;
  font-size: 18px;
  z-index: 2;
}
.cards_line {
  border-top: 1px solid #f0e7e0;
  border-bottom: 0px;
}
.cards_title {
  color: #f0e7e0 !important;
  text-shadow: 0px 4px 15px rgba(48, 48, 48, 0.87);
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
  text-transform: uppercase;
}
</style>
